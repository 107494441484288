<template>
  <div
    class="flex-column mt-1 address-detail address-edit-container"
    v-if="internalValue"
  >
    <h1
      class="font-weight-regular address-title mt-3 mb-4 px-2 px-sm-0 "
      v-html="titleSection"
    ></h1>
    <v-row>
      <v-col cols="12">
        <div v-if="showAutomplete">
          <v-form
            v-on:submit.prevent
            ref="form"
            class="add-address-form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <!-- <v-select
              return-object
              append-icon=""
              label="*Comune"
              outlined
              @change="setGeoLocationOptions()"
              v-model="comune"
              item-text="city"
              item-value="city"
            ></v-select> -->
            <!-- v-if="!validateAddress" -->
            <div class="d-flex align-center">
              <VueGoogleAutocomplete
                ref="address"
                :geolocationOptions="geolocationOptions"
                id="map"
                classname="py-4 new-delivery-address rounded-pill"
                background-color="white"
                placeholder="*Indirizzo"
                v-on:placechanged="getAddressData"
                :enable-geolocation="true"
                country="it"
                v-if="googleEnabled"
              >
              </VueGoogleAutocomplete>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!-- type="submit" -->
                  <v-btn
                    class="login-button"
                    large
                    depressed
                    icon
                    @click="geolocate"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>$locationCrosshairs</v-icon></v-btn
                  >
                </template>
                {{ $t("profile.addresses.geolocalize") }}
              </v-tooltip>
            </div>

            <v-alert border="left" type="error" v-if="error">{{
              $t(error)
            }}</v-alert>
          </v-form>
          <div class="my-2">Es. Via Verdi, 5, Milano, MI</div>
          <div class="my-4 missing-address-data" v-if="missingAddressData">
            {{ missingAddressDataMessage }}
          </div>
          <!-- v-if="!validateAddress" -->
          <v-row class="address-actions mt-6">
            <v-col cols="12" sm="6" class="d-flex justify-start">
              <v-btn
                @click="$router.go(-1)"
                color="primary"
                outined
                large
                depressed
                outlined
                class="rounded-pill edit-address-back-btn"
              >
                Annulla
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-end">
              <v-btn
                color="primary"
                class="primary-button rounded-pill"
                :disabled="checkAddressDisabled"
                @click="checkAddress"
                large
                depressed
              >
                {{ $t("profile.addresses.verify") }}
              </v-btn>
            </v-col>
          </v-row>

          <category-block
            :target="category"
            position="position1"
            class="category-block category-block-1"
          />
        </div>
        <v-form
          @submit.prevent.stop
          ref="createAddressForm"
          class="add-address-form"
        >
          <div v-if="!showAutomplete">
            <span class="d-block my-3 text-body-1">{{
              $t("profile.addresses.data")
            }}</span>

            <v-row>
              <v-col cols="12" sm="8"
                ><v-text-field
                  :label="`${$t('profile.addresses.city')}`"
                  :placeholder="`${$t('profile.addresses.city')}`"
                  v-model="internalValue.city"
                  readonly
                  outlined
                  filled
                  dense
                  hide-details="auto"
                  class="mt-1 rounded-pill"
                >
                </v-text-field
              ></v-col>
              <v-col cols="6" sm="2">
                <v-text-field
                  :label="`${$t('profile.addresses.province')}`"
                  :placeholder="$t('profile.addresses.province')"
                  v-model="internalValue.province"
                  readonly
                  outlined
                  filled
                  dense
                  hide-details="auto"
                  class="mt-1 rounded-pill"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="2">
                <v-text-field
                  :label="`${$t('profile.addresses.postalCode')}`"
                  :placeholder="$t('profile.addresses.postalCode')"
                  v-model="internalValue.postalcode"
                  readonly
                  outlined
                  filled
                  dense
                  hide-details="auto"
                  class="mt-1 rounded-pill"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field
                  :label="`${$t('profile.addresses.address')}`"
                  :placeholder="$t('profile.addresses.address')"
                  v-model="internalValue.address1"
                  filled
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-1 rounded-pill"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  :label="`${$t('profile.addresses.addressNumber')}`"
                  :placeholder="$t('profile.addresses.addressNumber')"
                  v-model="internalValue.addressNumber"
                  :filled="isFilled"
                  :readonly="isReadOnly"
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-1 rounded-pill"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('profile.addresses.type')"
                  :placeholder="$t('profile.addresses.typePlaceholder')"
                  class="mt-1 rounded-pill"
                  v-model="internalValue.addressName"
                  :rules="requiredRule"
                  @keyup="checkRequiredValues()"
                  outlined
                  required
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-if="homeDeliveryAvailable === false" class="my-3">
            <v-alert
              type="error"
              v-if="dataValidated.zone.zoneId !== everlyZoneId"
            >
              {{ $t("profile.addresses.deliveryNo") }}
            </v-alert>
            <v-alert v-else type="success">
              {{ $t("profile.address.everly.everlySuccessMsg") }}</v-alert
            >
            <!-- <h3 class="font-weight-semibold mb-3">
            {{ $t("profile.address.everly.title") }}
          </h3> -->

            <v-row
              v-if="dataValidated.zone.zoneId === everlyZoneId"
              class="align-center"
            >
              <v-col cols="12" md="4">
                <img
                  class="w-100 d-block mx-auto rounded-lg"
                  src="/img_layout/everly-logo.jpg"
                  alt="Everly Logo"
                />
              </v-col>
              <v-col cols="12" md="8">
                <!-- <p v-html="$t('profile.address.everly.description')"></p> -->
                <v-btn
                  depressed
                  large
                  color="primary"
                  class="rounded-pill"
                  target="_blank "
                  :href="$t('profile.address.everly.buttonLink')"
                >
                  {{ $t("profile.address.everly.buttonLabel") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <!--    <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center justify-sm-end mb-6 mb-sm-0"
            >
              <v-btn
                color="primary"
                class="primary-button"
                @click="addAnotherAddress"
                large
                depressed
              >
                {{ $t("profile.addresses.addAnotherAddress") }}
              </v-btn>
            </v-col>
          </v-row>-->

        <div
          v-if="homeDeliveryAvailable === true && !showAutomplete"
          class="mt-3"
        >
          <v-alert type="success" icon="$success">
            {{ $t("profile.addresses.deliveryYes") }}
          </v-alert>
          <!-- <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center justify-sm-end mb-6 mb-sm-0"
            >
              <v-btn
                color="primary"
                class="primary-button rounded-pill"
                @click="addAnotherAddress"
                large
                depressed
                outlined
              >
                {{ $t("profile.addresses.modifyAddress") }}
              </v-btn>
            </v-col>
          </v-row> -->
          <v-form
            v-on:submit.prevent
            ref="formOtherInfo"
            class="add-address-form"
            v-model="validInfo"
            :lazy-validation="lazy"
          >
            <v-row v-if="isEditing">
              <!-- <v-col cols="12" sm="6">
                <v-text-field
                  class="rounded-pill"
                  :label="$t('profile.addresses.type')"
                  :placeholder="$t('profile.addresses.typePlaceholder')"
                  v-model="internalValue.addressName"
                  :rules="[requiredRule()]"
                  outlined
                  required
                  dense
                >
                </v-text-field>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  class="rounded-pill"
                  :label="$t('profile.addresses.name')"
                  :placeholder="$t('profile.addresses.namePlaceholder')"
                  v-model="internalValue.doorbellName"
                  :rules="requiredRule"
                  outlined
                  required
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3">
                <!-- Piano -->
                <v-text-field
                  class="rounded-pill"
                  :label="$t('profile.addresses.floor')"
                  :placeholder="$t('profile.addresses.floorPlaceholder')"
                  v-model="internalValue.floor"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <!-- Ascensore -->
              <v-col cols="12" sm="3">
                <v-switch
                  v-model="internalValue.elevator"
                  :true-value="true"
                  :false-value="false"
                  inset
                  :label="$t('profile.addresses.elevator')"
                  dense
                  class="mt-2"
                  hide-details
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="3">
                <!-- Interno -->
                <v-text-field
                  class="rounded-pill"
                  :label="$t('profile.addresses.apartmentNumber')"
                  :placeholder="
                    $t('profile.addresses.apartmentNumberPlaceholder')
                  "
                  v-model="internalValue.apartmentNumber"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3">
                <v-text-field
                  class="rounded-pill"
                  :label="$t('profile.addresses.phone')"
                  :placeholder="$t('profile.addresses.phonePlaceholder')"
                  v-model="internalValue.referencePhoneNumber"
                  :rules="isGenericPhoneRules"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <!-- Altre info, addressNote -->
                <v-text-field
                  class="rounded-pill"
                  :label="$t('profile.addresses.other')"
                  :placeholder="$t('profile.addresses.otherPlaceholder')"
                  v-model="internalValue.addressNote"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>

              <!-- <v-col cols="12" sm="6">
      <v-text-field
        class="rounded-pill"
        :label="$t('profile.addresses.doorbellNumber')"
        :placeholder="$t('profile.addresses.doorbellNumberPlaceholder')"
        v-model="internalValue.doorbellNumber"
        outlined
        dense
      >
      </v-text-field>
    </v-col> -->

              <!-- <v-col
                cols="12"
                class="d-flex justify-center justify-sm-end mb-4"
              >
                <v-btn
                  color="primary"
                  class="primary-button rounded-pill"
                  @click="saveAddress"
                  large
                  depressed
                  :disabled="!checkRequiredValues"
                >
                  {{ $t("profile.addresses.save") }}
                </v-btn>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between mb-4">
                <v-btn
                  color="primary"
                  class="mr-3 rounded-pill"
                  :to="{ name: 'Addresses' }"
                  large
                  depressed
                  outlined
                >
                  {{ $t("profile.addresses.back") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="primary-button rounded-pill"
                  @click="saveAddress"
                  large
                  depressed
                >
                  {{ $t("profile.addresses.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <GmapMap
            ref="mapRef"
            :center="{
              lat: internalValue.latitude,
              lng: internalValue.longitude
            }"
            :zoom="16"
            :style="{ width: mapWidth, height: mapHeight }"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUI: false
            }"
          >
            <GmapMarker
              :position="
                google &&
                  new google.maps.LatLng(
                    internalValue.latitude,
                    internalValue.longitude
                  )
              "
              :draggable="true"
              :disablePanMomentum="true"
              @dragend="updateCoordinates"
              icon="/img_layout/pin.png"
            />
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick.stop="infoWinOpen = false"
            >
              {{ selectedWarehouse }}
              Contenuto
            </gmap-info-window>
            <gmap-circle
              v-if="addressToSearch"
              :center="{
                lat: addressToSearch.latitude,
                lng: addressToSearch.longitude
              }"
              :radius="addressToSearch.distance"
              :visible="true"
              :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
            >
            </gmap-circle>
          </GmapMap>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        homeDeliveryAvailable === false &&
          deliveryList &&
          deliveryList.length > 0
      "
    >
      <v-col cols="12">
        <h3 class="text-uppercase">
          {{ $t("profile.addresses.deliveryDrive") }}
        </h3>
        <v-list>
          <v-list-item-group>
            <v-list-item
              @click="setAddress(address)"
              class="mb-3 white"
              v-for="address in deliveryList"
              :key="address.addressId"
            >
              <v-list-item-icon>
                <v-img src="/favicon.ico" alt=""></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <address class="font-weight-semibold">
                  {{
                    `${address.addressName} ${address.address1} ${address.addressNumber} ${address.postalcode} ${address.city}`
                  }}
                </address></v-list-item-content
              ></v-list-item
            >
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <category-block
          :target="category"
          position="position2"
          class="category-block category-block-18"
        />
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.address-edit-container {
  .edit-address-back-btn {
    background-color: #ffffff;
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .vue-map {
    border: 1px solid var(--v-grey-lighten1);
    border-radius: 8px;
  }
  .new-delivery-address {
    background-color: white;
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  h3 {
    line-height: inherit;
  }
  address {
    font-style: normal;
  }
  .v-list {
    background-color: unset;
  }
  .section-title {
    font-size: 20px;
  }
  .add-address-form {
    #map {
      width: 100%;
      border: 1px solid var(--v-grey-base);
      border-radius: 5px;
      padding: 6px;
    }
  }
  .missing-address-data {
    color: var(--v-warning-base);
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .address-actions {
      .v-btn {
        width: 100%;
      }
    }
  }
  .v-autocomplete {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-input--switch {
    .v-input__slot {
      background-color: transparent !important;
    }
  }
  .mandatory {
    font-size: 14px;
  }
}
</style>
<script>
import GeoService from "~/service/geoService";
import DeliveryService from "~/service/deliveryService";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import gmapsInit from "@/components/store/gmaps";
//import login from "~/mixins/login";
import { mask } from "vue-the-mask";
import { requiredValue, isGenericPhone } from "~/validator/validationRules";
import clickHandler from "~/mixins/clickHandler";
import login from "@/commons/mixins/login";
import { mapGetters } from "vuex";
import categoryMixins from "~/mixins/category";
import { gmapApi } from "vue2-google-maps";

var google;

export default {
  name: "EditAddress",
  components: { VueGoogleAutocomplete },
  mixins: [clickHandler, login, categoryMixins],
  props: {
    showBackButton: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      valid: false,
      validInfo: false,
      // validRequiredValues: false,
      lazy: true,
      error: null,
      showAutomplete: true,
      homeDeliveryAvailable: null,
      googleEnabled: false,
      checkAddressDisabled: true,
      isReadOnly: true,
      isFilled: true,
      internalValue: {
        addressName: null,
        doorbellName: null,
        city: null,
        addressNote: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        floor: null,
        referencePhoneNumber: null,
        elevator: null,
        longitude: null,
        latitude: null,
        country: null,
        apartmentNumber: null,
        doorbellNumber: null
      },
      everlyZoneId: global.config.everlyZoneId,
      address: null,
      provinceList: [],
      cityList: [],
      addressList: [],
      userAddresses: [],
      postalcodeList: [],
      requiredRule: [requiredValue()],
      isGenericPhoneRules: [requiredValue(), isGenericPhone()],
      addressGeoCoded: null,
      validateAddress: false,
      missingAddressData: false,
      missingAddressDataMessage: null,
      addressDataInfo: [
        { field: "city", message: "città", required: true },
        { field: "address1", message: "indirizzo", required: true },
        { field: "postalcode", message: "CAP", required: true },
        { field: "province", message: "provincia", required: true },
        { field: "addressNumber", message: "civico", required: true }
      ],
      // floorItems: [
      //   "Piano terra",
      //   "1",
      //   "2",
      //   "3",
      //   "4",
      //   "5",
      //   "6",
      //   "Oltre il sesto"
      // ],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null,
      comune: null,
      options: [],
      geolocationOptions: null,
      checkCitySelected: true,
      showAddAddress: false,
      deliveryList: null,
      isEditing: false
    };
  },
  directives: { mask },
  computed: {
    ...mapGetters({
      storeId: "cart/getStoreId"
    }),
    checkRequiredValues() {
      return this.internalValue.addressName && this.internalValue.doorbellName;
    },
    enableSave() {
      return !this.valid;
    },
    titleSection() {
      return this.internalValue.addressId
        ? this.$t("profile.addresses.editAddressTitle", [
            this.internalValue.addressName
          ])
        : this.$t("profile.addresses.newAddressTitle");
    },
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "480px";
    }
  },

  methods: {
    updateCoordinates(location) {
      this.internalValue.latitude = location.latLng.lat();
      this.internalValue.longitude = location.latLng.lng();

      this.internalValue.gpsLatitude = location.latLng.lat();
      this.internalValue.gpsLongitude = location.latLng.lng();
    },
    async setAddress(address) {
      this.loading = true;

      if (this.$refs.createAddressForm.validate()) {
        await this.$store.dispatch("cart/setAddress", {
          addressType: address.addressType,
          addressId: address.addressId
        });
        await this.needTimeslot();
      }
      this.loading = false;
    },
    async fetchAddresses() {
      this.loading = true;
      try {
        this.userAddresses = await DeliveryService.getWarehousesList("home");
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    resetForm() {},
    setGeoLocationOptions() {
      let vm = this;
      const circle = new google.maps.Circle({
        center: {
          lat: this.comune.lat,
          lng: this.comune.lng
        },
        radius: this.comune.radius
      });
      this.$refs.address.autocomplete.setBounds(circle.getBounds());
      vm.checkCitySelected = false;
    },
    getAddressData(addressData, place) {
      //check @home
      let vm = this;
      this.missingAddressData = false;

      //provincia
      place.address_components.forEach(element => {
        if (element.types[0] == "administrative_area_level_2") {
          vm.internalValue.province = element.short_name;
          return;
        }
        if (element.types[0] == "country") {
          vm.internalValue.country = { iso: element.short_name };
          return;
        }
      });

      //città
      place.address_components.forEach(element => {
        if (
          element.types[0] == "administrative_area_level_3" ||
          element.types[0] == "locality"
        )
          //add custom variable
          vm.internalValue.city = element.short_name;
        return;
      });

      // vm.internalValue.city =
      //   addressData.administrative_area_level_3 ||
      //   addressData.locality ||
      //   addressData.administrative_area_level_2;
      vm.internalValue.postalcode = addressData.postal_code;
      vm.internalValue.addressNumber = addressData.street_number;
      vm.internalValue.address1 = addressData.route;
      vm.internalValue.latitude = addressData.latitude;
      vm.internalValue.gpsLatitude = addressData.latitude;
      vm.internalValue.gpsLongitude = addressData.longitude;
      vm.internalValue.longitude = addressData.longitude;
      vm.checkAddressDisabled = false;
    },
    async checkAddress() {
      let vm = this;
      vm.missingAddressData = false;
      vm.missingAddressDataMessage =
        "L'indirizzo inserito è incompleto, mancano i seguenti campi: ";
      vm.addressDataInfo.forEach(element => {
        if (element.required) {
          if (vm.internalValue[element.field] == undefined) {
            vm.missingAddressDataMessage += element.message + ", ";
            vm.missingAddressData = true;
          }
        }
      });
      if (vm.missingAddressData) {
        vm.missingAddressDataMessage = vm.missingAddressDataMessage.slice(
          0,
          -2
        );
        vm.missingAddressDataMessage += ".";
      } else {
        try {
          vm.dataValidated = await DeliveryService.isValidAddress({
            country: vm.internalValue.country,
            longitude: vm.internalValue.longitude,
            gpsLongitude: vm.internalValue.longitude,
            latitude: vm.internalValue.latitude,
            gpsLatitude: vm.internalValue.latitude,
            address1: vm.internalValue.address1,
            city: vm.internalValue.city,
            postalcode: vm.internalValue.postalcode,
            province: vm.internalValue.province,
            addressNumber: vm.internalValue.addressNumber
          });
          if (vm.dataValidated.active == 1) {
            vm.homeDeliveryAvailable = true;
            vm.showAutomplete = false;
          } else {
            var addressIdValue = 1;
            if (this.storeId === 1) {
              addressIdValue = 9;
            }
            if (this.storeId === 2) {
              addressIdValue = 1;
            }
            if (this.storeId === 3) {
              addressIdValue = 5;
            }
            const result = await DeliveryService.getAddressByDeliveryService(
              addressIdValue,
              vm.internalValue.gpsLatitude,
              vm.internalValue.gpsLongitude,
              25000
            );
            if (result && result.addresses && result.addresses.length) {
              this.deliveryList = result.addresses;
            }

            vm.homeDeliveryAvailable = false;
            vm.showAutomplete = false;
          }
          // if (vm.dataValidated.addressNumber) {
          //   vm.isFilled = true;
          //   vm.isReadOnly = true;
          // } else {
          //   vm.isFilled = false;
          //   vm.isReadOnly = false;
          // }
        } catch (err) {
          vm.homeDeliveryAvailable = false;
        }
        this.results = true;
      }
    },
    resetAddress() {
      this.selectedAddress = null;
      this.addressList = [];
      this.internalValue.address1 = null;
    },
    async getAddress(addressId) {
      this.internalValue = await DeliveryService.getAddresById(addressId);
    },
    async geocodeAddress(e) {
      let vm = this;
      e.preventDefault();
      if (vm.$refs.form.validate()) {
        let isGecoded = false;
        if (vm.internalValue.latitude && vm.internalValue.longitude) {
          isGecoded = true;
        } else {
          let address = await GeoService.geocodeAddress(vm.internalValue);
          if (address) {
            Object.assign(vm.internalValue, address);
            isGecoded = true;
          }
        }

        if (isGecoded) {
          vm.internalValue.city = vm.internalValue.city.toUpperCase();
          vm.internalValue.province = vm.internalValue.province.toUpperCase();
          //vm.internalValue = address;
          // vm.addressGeoCoded = Object.assign({}, address, vm.internalValue);
          // vm.addressGeoCoded.city = vm.addressGeoCoded.city.formatted_address;
          // vm.addressGeoCoded.address1 =
          //   vm.addressGeoCoded.address1.formatted_address;

          // //? valido l'indirizzo geocodificato
          vm.validateAndSaveAddress();
        } else {
          vm.error = "message.invalidAddress1";
        }
      }
    },
    addAnotherAddress() {
      this.showAutomplete = true;
      this.checkAddressDisabled = true;
      this.checkCitySelected = true;
      this.homeDeliveryAvailable = null;
      this.internalValue = {
        addressName: null,
        doorbellName: null,
        city: null,
        addressNote: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        floor: null,
        elevator: null,
        referencePhoneNumber: null,
        longitude: null,
        latitude: null
      };
    },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                vm.internalValue = GeoService.decodeGoogleAddress(results[0]);
                vm.internalValue.latitude = vm.internalValue.latitude();
                vm.internalValue.longitude = vm.internalValue.longitude();
                var addressFormat = GeoService.composeAddress(vm.internalValue);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                vm.checkAddress();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    },
    async validateAndSaveAddress() {
      let vm = this;
      var dataValidated = await DeliveryService.isValidAddress(
        vm.internalValue
      );
      //? se non e' valido mostro snakcbar errore
      if (dataValidated.active === 0) {
        vm.error = "message.invalidAddress1";
      } else {
        if (
          !vm.internalValue.address1 ||
          !vm.internalValue.addressNumber ||
          !vm.internalValue.postalcode ||
          !vm.internalValue.city
        ) {
          vm.error = "message.invalidAddress1";
        } else {
          const text =
            "E' stato trovato questo indirizzo: <b>" +
            vm.internalValue.address1 +
            " " +
            vm.internalValue.addressNumber +
            ", " +
            vm.internalValue.postalcode +
            " " +
            vm.internalValue.city +
            "</b>. Vuoi salvarlo?";
          const dialogRes = await this.$dialog.confirm({ text });
          // eslint-disable-next-line no-unused-vars
          // _this.$emit("removeAddress", address);
          if (dialogRes) {
            vm.saveAddress();
          }
        }
      }
    },
    async saveAddressRequest() {
      let vm = this;
      let addressUpdated = await DeliveryService.updateAddress(
        vm.internalValue
      );
      if (addressUpdated) {
        vm.$emit("save");
        vm.$router.push({
          name: "Addresses"
        });
      }
    },
    saveAddress() {
      if (this.isEditing) {
        if (this.$refs.formOtherInfo.validate()) {
          this.saveAddressRequest();
        }
      } else {
        if (
          this.$refs.formOtherInfo.validate() &&
          this.$refs.createAddressForm.validate()
        ) {
          this.saveAddressRequest();
        }
      }
    }
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    }
  },
  async created() {
    await this.fetchAddresses();
    let vm = this;
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (err) {
      console.log(err);
    }
    if (vm.$route.params.addressId) {
      this.showAutomplete = false;
      if (vm.$route.params.addressId) {
        this.addAnotherAddress();
      }
      this.homeDeliveryAvailable = true;
      await vm.getAddress(vm.$route.params.addressId);
    }
    // vm.fetchDefaultData();
  },
  mounted() {
    this.resetForm();
    if (this.$route.params.editAddress) {
      this.isEditing = true;
      this.addAnotherAddress();
    }
  }
};
</script>
